<form name="form"
      [formGroup]="form"
      omgFocusProvider
      #focus="omgFocusProvider">
  <div om-layout>
    <omg-card-body omgScrollingContainer
                   om-flex>
      <ng-content select=".before-summary" />
      <ng-content *ngTemplateOutlet="summaryIsWalkinVisit ? walkinSummary : defaultSummary"></ng-content>
    </omg-card-body>
    <omg-card-body class="border-left"
                   *ngIf="workspaceExpanded$ | ngrxPush"
                   om-flex="50">
      <omg-service-ticket [visitProcedureId]="summary.visitProcedureId"
                          [signed]="summary.signed"></omg-service-ticket>
    </omg-card-body>
  </div>
  <omg-card-footer>
    <div class="action-bar"
         om-layout="vertical"
         om-layout-align="center"
         omgCollapse
         #redactConfirmRef="omgCollapse">
      <omg-collapsed *ngIf="!summary.redactedAt">
        <span *ngIf="!showAddendumForm"
              om-layout
              om-layout-gutter>
          <!-- Bug: Dropdown appears above when adding cosignature -->
          <omg-split-button [label]="'Add ' + addendumText"
                            [items]="items"
                            (labelClick)="onClick(focus)"></omg-split-button>
          <button omgButton
                  *ngIf="visitProcedure$ | ngrxPush as visitProcedure"
                  variant="secondary"
                  (click)="toggleServiceTicket()"
                  [omgTrack]="{
                    event: 'click',
                    action: 'Toggle Service Ticket'
                  }"
                  data-cy="toggle-service-ticket-btn">{{ visitProcedure.serviceTicket.serviceTicketTransmission.locked ?
            'View' : 'Edit' }}
            Service
            Ticket</button>
        </span>
        <span *ngIf="showAddendumForm"
              om-layout
              om-layout-gutter
              om-layout-align="space-between center">
          <!-- Todo: Add disabled attribute to button -->
          <button omgButton
                  variant="primary"
                  type="submit"
                  (click)="createAddendum()"
                  [disabled]="form.invalid || submitInProgress">
            {{ addendumSignButtonText }}
          </button>
          <button omgButton
                  variant="link"
                  type="button"
                  (click)="closeAddendumForm()">
            Delete {{ addendumText }}
          </button>
        </span>
      </omg-collapsed>
      <omg-expanded om-layout="vertical"
                    om-layout-align="center">
        <div om-layout
             om-layout-align="space-between">
          <b>Permanently redact this note?</b>
          <span om-layout
                om-layout-gutter>
            <button omgButton
                    omgCollapseToggle
                    variant="flat">Never mind</button>
            <button omgButton
                    variant="primary"
                    (click)="redact()">Redact</button>
          </span>
        </div>
      </omg-expanded>
    </div>
  </omg-card-footer>
  <ng-template #defaultSummary>
    <div>

      <div *ngIf="summary.redactedAt"
           class="banner -warning">
        <p>This note and vitals entered during this visit have been redacted.</p>
      </div>

      <div *ngIf="!summary.redactedAt">
        <!-- Comments -->
        <omg-expanded [collapseProvider]="commentsCollapseRef">
          <omg-comments [commentable]="commentable"
                        (commentAdded)="onCommentUpdate('add')"
                        (commentRemoved)="onCommentUpdate('remove')"></omg-comments>
        </omg-expanded>

        <div *ngIf="summary.appointment && !summary.hasProgramVisit"
             class="banner -flush">CC: "{{ summary.appointment.reason }}</div>
        <omg-task-assignment [todo]="todo"
                             *ngIf="hasIncompleteCosignTodo"></omg-task-assignment>


        <div class="program-visit-container padding-normal"
             *ngIf="summary.hasProgramVisit">
          <omg-program-visit [summaryId]="summary.id"
                             [chronicCareBillingPrograms]="summary.chronicCareBillingPrograms"></omg-program-visit>
        </div>

        <omg-chronic-care-management *ngIf="summary.hasProgramVisit"
                                     [completed]="true"
                                     [summaryId]="summary.id"></omg-chronic-care-management>
        <ng-container *ngIf="!summary.hasProgramVisit">
          <omg-section-title>
            Subjective
          </omg-section-title>
          <p class="padding-normal">{{ summary.subjective }}</p>

          <omg-linked-problems *ngIf="summary.medicalHistory"
                               [activeProblems]="summary.activeProblems"
                               [resolvedProblems]="summary.resolvedProblems"
                               [signed]="summary.signed"></omg-linked-problems>
          <omg-linked-allergies *ngIf="summary.patientAllergies.length || summary.noKnownAllergies"
                                [noKnownAllergies]="summary.noKnownAllergies"
                                [patientAllergies]="summary.patientAllergies"
                                [signed]="summary.signed"></omg-linked-allergies>
          <omg-linked-medications *ngIf="summary.medications.length || summary.noMedications"
                                  [medications]="summary.medications"
                                  [signed]="summary.signed"></omg-linked-medications>
          <omg-linked-vaccines *ngIf="summary.vaccinations.length"
                               [vaccinations]="summary.vaccinations"
                               [signed]="summary.signed"></omg-linked-vaccines>
          <omg-linked-health-background *ngIf="summary.healthBackground"
                                        [healthBackground]="summary.healthBackground"
                                        [smokingStatus]="summary.smokingStatus"
                                        [implantableDevices]="summary.implantableDevices"
                                        [signed]="summary.signed"></omg-linked-health-background>
          <omg-linked-family-health-history *ngIf="summary.familyHealthHistory"
                                            [familyHealthHistory]="summary.familyHealthHistory"
                                            [signed]="summary.signed">
          </omg-linked-family-health-history>

          <div *ngIf="!summary.redactedAt">
            <omg-section-title>
              Vitals
            </omg-section-title>
            <omg-summary-measurements-table [vitals]="summary.vitals"
                                            [showInfantVitals]="summary.createdForInfant">
            </omg-summary-measurements-table>
          </div>

          <div *ngIf="showGrowthPercentiles && (summary.createdForInfant || summary.createdForChild)">
            <omg-section-title *ngIf="summary.createdForInfant || summary.createdForChild">
              Growth Chart Percentiles
            </omg-section-title>
            <omg-summary-growth-chart [vitals]="summary.vitals"
                                      [forInfant]="summary.createdForInfant"></omg-summary-growth-chart>
          </div>

          <omg-section-title>
            Exam
          </omg-section-title>
          <p class="padding-normal"
             *ngIf="summary.objective">{{ summary.objective }}</p>
        </ng-container>

        <omg-procedures-and-services *ngIf="summary.serviceTicketItems?.length > 0"
                                     [serviceTicketItems]="summary.serviceTicketItems"
                                     [showServiceTicketDeleteError]="showServiceTicketDeleteError"
                                     (unlink)="removeServiceTicketItems($event)">
        </omg-procedures-and-services>
        <omg-linked-assessment-plan *ngIf="summary.assessedProblems.length"
                                    [assessedProblems]="summary.assessedProblems"
                                    [signed]="summary.signed"></omg-linked-assessment-plan>
        <omg-signed-after-visit-guidance *ngIf="displayAfterVisitGuidence"
                                         [summaryProviderRecommendation]="summary.summaryProviderRecommendation">
        </omg-signed-after-visit-guidance>
        <omg-linked-health-maintenance *ngIf="
                                       summary.healthGoals.length
                                       ||
                                       summary.healthMaintenanceNote.content"
                                       [healthMaintenanceNote]="summary.healthMaintenanceNote"
                                       [healthGoals]="summary.healthGoals"
                                       [signed]="summary.signed">
        </omg-linked-health-maintenance>

        <div *ngIf="summary.attachableFiles.length > 0">
          <omg-section-title>
            Attachments
          </omg-section-title>
          <div class="om-list">
            <div class="om-item"
                 *ngFor="let attachment of summary.attachableFiles">
              <strong>
                <i class="fa fa-file-o"></i>
                <a [href]="attachment.viewingPath"
                   target="_blank">{{ attachment.name }}</a>
              </strong>
            </div>
          </div>
        </div>
        <div *ngIf="visitProcedure$ | ngrxPush as visitProcedure"
             class="padding-normal">
          <strong>{{timeBasedVisitLabel(visitProcedure)}}</strong>
        </div>

        <omg-summary-addenda *ngIf="summary.summaryAddendums.length > 0"
                             [addenda]="summary.summaryAddendums"></omg-summary-addenda>
        <div class="padding-normal"
             *ngIf="showAddendumForm">
          <label om-layout-fill
                 om-layout="vertical">Signed by {{ profilePrimaryName }}
            <omg-chart-text-box [control]="form.get('addendumContent')"
                                [insertionEventProps]="{
                                component: summary.noteType.name,
                                subcomponent: addendumAnalyticsSubcomponent
                              }"
                                #addendumTextArea
                                omgFocusOn="addendumFocus-add"
                                (focusOnRequest)="addendumTextArea.focus()">
            </omg-chart-text-box>
          </label>
          <div class="alert"
               *ngIf="savingError">
            {{ messages.savingFailedMessage }}
          </div>
        </div>
        <omg-transmission-details *ngIf="note && note.faxes.length > 0"
                                  [history]="note.faxes"></omg-transmission-details>
      </div>
    </div>
  </ng-template>
  <ng-template #walkinSummary>
    <div>

      <!-- Comments -->
      <omg-expanded [collapseProvider]="commentsCollapseRef">
        <omg-comments [commentable]="commentable"
                      (commentAdded)="onCommentUpdate('add')"
                      (commentRemoved)="onCommentUpdate('remove')"></omg-comments>
      </omg-expanded>

      <omg-section-title>
        Vitals
      </omg-section-title>
      <omg-summary-measurements-table [vitals]="summary.vitals"
                                      [showInfantVitals]="summary.createdForInfant"></omg-summary-measurements-table>

      <omg-procedures-and-services *ngIf="summary.serviceTicketItems?.length > 0"
                                   [serviceTicketItems]="summary.serviceTicketItems"
                                   [showServiceTicketDeleteError]="showServiceTicketDeleteError"
                                   (unlink)="removeServiceTicketItems($event)"></omg-procedures-and-services>
      <omg-linked-assessment-plan *ngIf="summary.assessedProblems.length"
                                  [assessedProblems]="summary.assessedProblems"
                                  [signed]="summary.signed"></omg-linked-assessment-plan>
      <div *ngIf="summary.attachableFiles.length > 0">
        <omg-section-title>
          Attachments
        </omg-section-title>
        <div class="om-list">
          <div class="om-item"
               *ngFor="let attachment of summary.attachableFiles">
            <strong>
              <i class="fa fa-file-o"></i>
              <a [href]="attachment.viewingPath"
                 target="_blank">{{ attachment.name }}</a>
            </strong>
          </div>
        </div>
      </div>

      <omg-summary-addenda *ngIf="summary.summaryAddendums.length > 0"
                           [addenda]="summary.summaryAddendums"></omg-summary-addenda>
      <div class="padding-normal"
           *ngIf="showAddendumForm">
        <label om-layout-fill
               om-layout="vertical">Signed by {{ profilePrimaryName }}
          <textarea omgTextarea
                    autosize
                    rows="2"
                    om-layout-fill
                    formControlName="addendumContent"
                    #addendumTextArea
                    omgFocusOn="addendumFocus-add"
                    (focusOnRequest)="addendumTextArea.focus()"></textarea>
        </label>
        <div class="alert"
             *ngIf="savingError">
          {{ messages.savingFailedMessage }}
        </div>
      </div>
    </div>
  </ng-template>
</form>

<omg-printable-note *ngIf="patient && note"
                    [hasDocuments]="hasDocuments"
                    [patient]="patient"
                    [summary]="summary"
                    [note]="note">
</omg-printable-note>
