<ul class="padding-normal"
    om-layout="vertical"
    om-layout-gutter>
  <li class="om-animate"
      *ngFor="let problem of assessedProblems; trackBy: trackByFn">
    <div om-layout
         om-layout-align="space-between center">
      <div class="enhanced-ap-title">
        <span>{{ problem.problemType.clinicalDescription }}</span>
        <span *ngIf="problem.problemCodeLocation?.name">, {{problem.problemCodeLocation.name}}</span>
        <span *ngIf="problem.onset || problem.resolution"> (
          <span *ngIf="problem.onset">onset {{ problem.onset }}</span>
          <span *ngIf="problem.onset && problem.resolution">, </span>
          <span *ngIf="problem.resolution">resolved {{ problem.resolution }}</span>
          <span>)</span>
        </span>
        <span *ngIf="problem.code"> | {{ problem.code }}</span>
      </div>
      <span class="om-icon clickable fa fa-trash"
            (click)="deleteFromNote(problem)"
            *ngIf="!signed"
            omgStopEvent="click"
            omgTooltip
            tooltipText="Delete from Note"
            tooltipPosition="left"></span>
    </div>
    <div *ngIf="problem.briefComment && !isWhitespace(problem.briefComment)"
         class="enhanced-ap-section-body">{{ problem.briefComment }}</div>
    <div *ngIf="problem.summary && !isWhitespace(problem.summary)">
      <div class="enhanced-ap-section-header">Summary</div>
      <div class="enhanced-ap-section-body">{{ problem.summary }}</div>
    </div>
    <div *ngIf="problem.aAndP">
      <div class="enhanced-ap-section-header">Assessment & Plan</div>
      <div class="enhanced-ap-section-body"
           [innerText]="problem.aAndP"></div>
    </div>
    <div *ngIf="problem.attachments.patientMedications?.length > 0">
      <div class="enhanced-ap-section-header">Medications</div>
      <div class="enhanced-ap-med-item"
           *ngFor="let medication of problem.attachments.patientMedications">
        {{ medication.name }} | {{ medication.regimen }}
      </div>
    </div>
  </li>
</ul>
